<template>
  <div class="bg-wrapper">
    <div class="container">
      <div class="contact-us__inner contact-us">
        <div class="contact-us-title default-title">Маєте ще питання?</div>
        <div
          class="contact-us-title default-title"
          style="color: var(--G1, #16471c); margin-bottom: 40px"
        >
          Зв'яжіться з нами!
        </div>
        <div class="contact-us-title-mobile">
          Для замовлення зв'яжіться з нами!
        </div>
        <div class="d-flex align-center justify-center">
          <button
            class="default-btn contact-us-btn"
            @click="dialogVisible = true"
          >
            Зв'язатися з нами
          </button>
        </div>
      </div>
      <dialogComponent
        :visible="dialogVisible"
        @close="dialogVisible = false"
        v-if="dialogVisible"
      />
    </div>
  </div>
</template>

<script>
import dialogComponent from "@/components/dialogs/dialogComponent.vue";
export default {
  data: () => ({
    dialogVisible: false,
  }),
  components: {
    dialogComponent,
  },
};
</script>
<style scoped>
.contact-us {
  background-image: url("../assets/contact-us-bg.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.contact-us-title {
  margin-bottom: 12px;
}
.contact-us__inner {
  padding: 120px;
}
.contact-us-btn {
  max-width: 380px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
}
.contact-us-title-mobile {
  display: none;
}
@media (max-width: 700px) {
  .contact-us {
    background: none;
  }
  .contact-us__inner {
    padding: 50px;
  }
}
@media (max-width: 500px) {
  .contact-us__inner {
    padding: 15px 0;
  }
  .contact-us-title {
    display: none;
  }
  .contact-us-title-mobile {
    display: inline-block;
    color: var(--black, #1b1b1b);
    text-align: center;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    text-align: center;
    max-width: 100%;
    width: 100%;
  }
  .contact-us-btn {
    border-radius: 35px;
    background: linear-gradient(90deg, #15471d 0%, #46a807 100%);
    max-width: 100%;
    width: 100%;
  }
}
</style>
