<template>
  <v-dialog v-model="visibility" content-class="navigation-dialog" fullscreen>
    <v-card>
      <div class="navigation-header">
        <div class="navigation-logo">
          <a href="#"><img src="../../assets/logo.png" alt="logo" /></a>
        </div>
        <div class="navigation-close">
          <v-btn icon @click="visibility = false" x-large
            ><v-icon color="black">mdi-close</v-icon></v-btn
          >
        </div>
      </div>
      <div class="navigation__list">
        <div class="navigation__list-item" @click="scrollToId('benefits')">
          Наші переваги
        </div>
        <div class="navigation__list-item" @click="scrollToId('products')">
          Наша продукція
        </div>
        <div class="navigation__list-item" @click="scrollToId('aboutUs')">
          Про нас
        </div>
        <div
          class="navigation__list-item"
          @click="scrollToId('productsGallery')"
        >
          Галерея
        </div>
      </div>
      <!-- <div class="navigation__icons">
        <div class="navigation__icons-icon">
          <a href="mailto:UkrGreenTech@gmail.com"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <path
                d="M15.6145 17.8313V13.7325H29.7896C29.9282 14.4462 30 15.2913 30 16.2062C30 19.2812 29.1379 23.0838 26.3565 25.7925C23.6521 28.5363 20.1971 30 15.6197 30C7.13437 30 0 23.2663 0 15C0 6.73375 7.13437 0 15.6197 0C20.3139 0 23.6572 1.795 26.1692 4.13375L23.2005 7.025C21.3992 5.37875 18.9578 4.09875 15.6145 4.09875C9.41926 4.09875 4.57364 8.96375 4.57364 15C4.57364 21.0362 9.41926 25.9013 15.6145 25.9013C19.6327 25.9013 21.9214 24.3287 23.3878 22.9C24.5771 21.7412 25.3596 20.0863 25.6676 17.8263L15.6145 17.8313Z"
                fill="black"
              /></svg
          ></a>
        </div>
        <div class="navigation__icons-icon">
          <a href="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <g clip-path="url(#clip0_199_1512)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 0.363281C6.71625 0.363281 0 7.07953 0 15.3633C0 23.647 6.71625 30.3633 15 30.3633C23.2838 30.3633 30 23.647 30 15.3633C30 7.07953 23.2838 0.363281 15 0.363281ZM21.9525 10.5645C21.7275 12.9358 20.75 18.692 20.2537 21.3483C20.0437 22.4733 19.6287 22.8495 19.2288 22.8858C18.3575 22.9658 17.6963 22.3095 16.8525 21.757C15.5325 20.892 14.7863 20.3533 13.505 19.5083C12.0238 18.532 12.9838 17.9958 13.8275 17.1195C14.0487 16.8895 17.8863 13.3983 17.9613 13.082C17.97 13.042 17.98 12.8945 17.8913 12.817C17.8025 12.7395 17.6737 12.7658 17.5812 12.787C17.4487 12.817 15.34 14.2108 11.2537 16.9683C10.655 17.3795 10.1125 17.5795 9.62625 17.5695C9.09125 17.5583 8.06125 17.267 7.295 17.017C6.35625 16.712 5.60875 16.5495 5.67375 16.032C5.7075 15.762 6.07875 15.4858 6.78875 15.2033C11.1613 13.2983 14.0775 12.042 15.5363 11.4345C19.7025 9.70203 20.5675 9.40078 21.1325 9.39078C21.84 9.38078 22.01 9.96453 21.9525 10.5645Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_199_1512">
                  <rect width="30" height="30" fill="white" />
                </clipPath>
              </defs></svg
          ></a>
        </div>
        <div class="navigation__icons-icon">
          <a href="#"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <g clip-path="url(#clip0_199_1507)">
                <path
                  d="M15 3.06578C19.005 3.06578 19.48 3.08078 21.0613 3.15328C22.6963 3.22828 24.38 3.60078 25.5713 4.79203C26.7738 5.99453 27.135 7.66203 27.21 9.30203C27.2825 10.8833 27.2975 11.3583 27.2975 15.3633C27.2975 19.3683 27.2825 19.8433 27.21 21.4245C27.1362 23.0508 26.755 24.7508 25.5713 25.9345C24.3688 27.137 22.7025 27.4983 21.0613 27.5733C19.48 27.6458 19.005 27.6608 15 27.6608C10.995 27.6608 10.52 27.6458 8.93875 27.5733C7.325 27.4995 5.6025 27.1095 4.42875 25.9345C3.2325 24.7383 2.865 23.0545 2.79 21.4245C2.7175 19.8433 2.7025 19.3683 2.7025 15.3633C2.7025 11.3583 2.7175 10.8833 2.79 9.30203C2.86375 7.68203 3.24875 5.97203 4.42875 4.79203C5.62875 3.59203 7.3025 3.22828 8.93875 3.15328C10.52 3.08078 10.995 3.06578 15 3.06578ZM15 0.363281C10.9262 0.363281 10.415 0.380781 8.815 0.453281C6.49625 0.559531 4.19375 1.20453 2.5175 2.88078C0.835 4.56328 0.19625 6.86078 0.09 9.17828C0.0175 10.7783 0 11.2895 0 15.3633C0 19.437 0.0175 19.9483 0.09 21.5483C0.19625 23.8645 0.84375 26.1733 2.5175 27.8458C4.19875 29.527 6.5 30.167 8.815 30.2733C10.415 30.3458 10.9262 30.3633 15 30.3633C19.0737 30.3633 19.585 30.3458 21.185 30.2733C23.5025 30.167 25.8075 29.5208 27.4825 27.8458C29.1663 26.162 29.8037 23.8658 29.91 21.5483C29.9825 19.9483 30 19.437 30 15.3633C30 11.2895 29.9825 10.7783 29.91 9.17828C29.8037 6.85953 29.1575 4.55578 27.4825 2.88078C25.8037 1.20203 23.4963 0.558281 21.185 0.453281C19.585 0.380781 19.0737 0.363281 15 0.363281Z"
                  fill="black"
                />
                <path
                  d="M14.9994 7.66016C10.7456 7.66016 7.29688 11.1089 7.29688 15.3627C7.29688 19.6164 10.7456 23.0652 14.9994 23.0652C19.2531 23.0652 22.7019 19.6164 22.7019 15.3627C22.7019 11.1089 19.2531 7.66016 14.9994 7.66016ZM14.9994 20.3627C12.2381 20.3627 9.99938 18.1239 9.99938 15.3627C9.99938 12.6014 12.2381 10.3627 14.9994 10.3627C17.7606 10.3627 19.9994 12.6014 19.9994 15.3627C19.9994 18.1239 17.7606 20.3627 14.9994 20.3627Z"
                  fill="black"
                />
                <path
                  d="M23.007 9.15566C24.0011 9.15566 24.807 8.34978 24.807 7.35566C24.807 6.36155 24.0011 5.55566 23.007 5.55566C22.0129 5.55566 21.207 6.36155 21.207 7.35566C21.207 8.34978 22.0129 9.15566 23.007 9.15566Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_199_1507">
                  <rect width="30" height="30" fill="white" />
                </clipPath>
              </defs></svg
          ></a>
        </div>
      </div> -->
      <div class="navigation-phone">
        <a href="tel:+380677573791">+38 (067) 757-37-91</a>
      </div>
      <div class="navigation-phone">
        <a href="mailto:office@ugt.urg.ua">office@ugt.urg.ua</a>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    async scrollToId(id) {
      if (this.$route.path != "/photos") {
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
          behavior: "smooth",
        });
        this.visibility = false;
      } else {
        await this.$router.push("/");
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
          top: targetPosition - scrollOffset,
        });
        this.visibility = false;
      }
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      },
    },
  },
};
</script>
<style>
.navigation-dialog {
  transition: none !important;
  height: 100svh;
}
.navigation-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  height: 110px;
  align-items: center;
  margin-bottom: 100px;
}
.navigation__list {
  margin-bottom: 40px;
}
.navigation__list-item {
  color: #000;
  font-family: "MacPaw Fixel";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}
.navigation__list-item + .navigation__list-item {
  margin-top: 40px;
}
.navigation__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 30px;
}
.navigation__icons-icon + .navigation__icons-icon {
  margin-left: 53px;
}
.navigation-phone {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.navigation-phone a {
  color: #000 !important;
  text-align: center;
  font-family: "MacPaw Fixel";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}
</style>
